<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="window-maximize"/> Forms Data</CCardHeader>
            <CCardBody>
                
                <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                    <br>
                    <CTab>
                        <template slot="title">
                            Active Form Application/s <CBadge color="success">( {{ [...new Set(active_forms.map((item) => item.reference_number))].length }} )</CBadge>
                        </template>
                        <CDataTable
                            :items="active_forms"
                            :fields="form_data_list_fields"
                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                            :items-per-page="10"
                            border
                            items-per-page-select
                            sorter
                            pagination
                            :loading="this.table_loading"
                        >
                        <template slot="no-items-view" v-if="this.table_loading">
                            <center>
                                <CSpinner
                                    style="width:4rem;height:4rem;"
                                    color="info"
                                />
                            </center>
                        </template>
                        <template #form="{item}">
                            <td>
                            {{item.form_details.form.name}}
                            </td>
                        </template>
                        <template #created_at="{item}">
                            <td>
                                {{changeDateFormatV2(item.created_at)}}
                            </td>
                        </template>
                        <template #routing="{item}">
                            <td v-if="item.routing">
                            <CBadge :color="getColorStatus(getDocumentStatus(item.routing.status))">
                                    {{ getDocumentStatus(item.routing.status).charAt(0).toUpperCase() + getDocumentStatus(item.routing.status).slice(1)}}
                                </CBadge>
                            </td>
                            <td v-else>
                            <i>Not yet routed.</i>
                            </td>
                        </template>
                        <template #action="{item}">
                            <td>
                                <template v-if="!item.routing">
                                    <router-link :to="{path: `/${$store.getters['getOrganizationName']}/general-access/form-data/${item.reference_number}~${item.form_details.form.id}`}">
                                        <CButton 
                                            color="info" 
                                            shape="pill" 
                                            size="sm"
                                        >
                                            <font-awesome-icon icon="cog"/> Manage
                                        </CButton>
                                    </router-link>
                                </template>
                                <CButton 
                                    color="primary" 
                                    shape="pill" 
                                    size="sm"
                                    :disabled="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                    @click="previewFormData(item)"

                                >
                                    <font-awesome-icon :icon="previewing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                            :pulse="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"/> Preview Form Data
                                </CButton>
                                <template v-if="!item.routing && !item.deleted_at">
                                    <CButton 
                                        color="warning" 
                                        shape="pill" 
                                        size="sm"
                                        :disabled="routing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                        @click="routeFormData(item)"
            
                                    >
                                        <font-awesome-icon :icon="routing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'paper-plane') : 'paper-plane'" 
                                                                :pulse="routing_form_data ? (item.id == selected_row_id ? true : false) : false"/> Route Form Data
                                    </CButton>
                                </template>
                            </td>
                        </template>
                        <!-- <template #status="{item}">
                            <td>
                                <CBadge :color="getColorStatus(!item.deleted_at ? 'Active' : 'Inactive')">
                                    {{!item.deleted_at ? 'Active' : 'Inactive'}}
                                </CBadge>
                            </td>
                        </template> -->
                        </CDataTable>
                    </CTab>
                    <CTab>
                        <template slot="title">
                            Inactive Form Application/s <CBadge color="danger">( {{ [...new Set(inactive_forms.map((item) => item.reference_number))].length }} )</CBadge>
                        </template>
                        <CDataTable
                            :items="inactive_forms"
                            :fields="form_data_list_fields"
                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                            :items-per-page="10"
                            border
                            items-per-page-select
                            sorter
                            pagination
                            :loading="this.table_loading"
                        >
                        <template slot="no-items-view" v-if="this.table_loading">
                            <center>
                                <CSpinner
                                    style="width:4rem;height:4rem;"
                                    color="info"
                                />
                            </center>
                        </template>
                        <template #form="{item}">
                            <td>
                            {{item.form_details.form.name}}
                            </td>
                        </template>
                        <template #updated_at="{item}">
                            <td>
                                <small>{{changeDateFormat(item.updated_at)}}</small>
                            </td>
                        </template>
                        <!-- <template #routing="{item}">
                            <td v-if="item.routing">
                            <CBadge :color="getColorStatus(getDocumentStatus(item.routing.status))">
                                    {{ getDocumentStatus(item.routing.status).charAt(0).toUpperCase() + getDocumentStatus(item.routing.status).slice(1)}}
                                </CBadge>
                            </td>
                            <td v-else>
                            <i>Not yet routed.</i>
                            </td>
                        </template> -->
                        <template #action="{item}">
                            <td>
                                <template v-if="$store.getters['can']('restore-form-data')">
                                    <CButton 
                                        size="sm"
                                        color="success" 
                                        shape="pill"
                                        :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                        @click="restore(item)"
                                    >

                                        <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                    :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                    </CButton>
                                </template>
                                <template v-else>
                                    <i><b>No permission to restore.</b></i>
                                </template>
                            </td>



                            <!-- <td> -->
                                <!-- <template v-if="!item.routing">
                                    <router-link :to="{path: `/${$store.getters['getOrganizationName']}/general-access/form-data/${item.reference_number}~${item.form_details.form.id}`}">
                                        <CButton 
                                            color="info" 
                                            shape="pill" 
                                            size="sm"
                                        >
                                            <font-awesome-icon icon="cog"/> Manage
                                        </CButton>
                                    </router-link>
                                </template>
                                <CButton 
                                    color="primary" 
                                    shape="pill" 
                                    size="sm"
                                    :disabled="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                    @click="previewFormData(item)"

                                >
                                    <font-awesome-icon :icon="previewing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                            :pulse="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"/> Preview Form Data
                                </CButton>
                                <template v-if="!item.routing && !item.deleted_at">
                                    <CButton 
                                        color="warning" 
                                        shape="pill" 
                                        size="sm"
                                        :disabled="routing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                        @click="routeFormData(item)"
            
                                    >
                                        <font-awesome-icon :icon="routing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'paper-plane') : 'paper-plane'" 
                                                                :pulse="routing_form_data ? (item.id == selected_row_id ? true : false) : false"/> Route Form Data
                                    </CButton>
                                </template> -->
                            <!-- </td> -->
                        </template>
                        <template #preview="{item}">
                            <td>
                                <center>
                                    <CButton 
                                        color="warning" 
                                        shape="pill" 
                                        size="sm"
                                        :disabled="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                        @click="previewFormData(item)"
                                    >
                                        <font-awesome-icon :icon="previewing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                                :pulse="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"/>
                                    </CButton>
                                </center>
                            </td>
                        </template>
                        <!-- <template #status="{item}">
                            <td>
                                <CBadge :color="getColorStatus(!item.deleted_at ? 'Active' : 'Inactive')">
                                    {{!item.deleted_at ? 'Active' : 'Inactive'}}
                                </CBadge>
                            </td>
                        </template> -->
                        </CDataTable>
                    </CTab>
                </CTabs>

                
            </CCardBody>
        </CCard>   
        <template>
            <CCard style="display:none">
            <!-- <CCard> -->
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <FormDataPreview ref="_formDataRoute" id="form_data"/>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </template>
        <CModal 
            color="primary" 
            :show.sync="preview_form_data" 
            centered
            :closeOnBackdrop="false"
            size="xl"
        >
            <template #header>
                <h5><font-awesome-icon icon="window-maximize" /> Form Data Preview</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="preview_form_data = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper v-if="preview_form_data">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol lg="12">
                                <FormDataPreview ref="_formDataPreview"/>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </template>
            <template #footer>
                <hr>
            </template>
        </CModal>
    </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import FormManagementJS from '../js/form-management.js';
import FormDataPreview from './components/FormDataPreview'
export default {
    name: 'FDLists',
    components: {
        FormDataPreview
    },
    data(){
        return {
            my_tab: 0,
            printout_source: '',
            selected_row_id: null,
            previewing_form_data:false,
            preview_form_data: false,
            routing_form_data:false,
            form_data_preview_loaded: false,
            table_loading: true,

            
            restoring: false,
            restoring_id: null,
            form_data_list: [
                // {id: 1, name: 'Document 1305', form: 'CRF', status: 'Active'},
                // {id: 2, name: 'Approval 2316', form: 'PRS', status: 'Active'},
                // {id: 3, name: 'JO-PRS 137', form: 'JO', status: 'Active'},
            ]
        }
    },
    created() {
        this.getFormsData();
        this.$emit('activeTab', 0);
    },
    computed: {
        form_data_list_fields: function () {
            let custom_fields = [];

            if(this.my_tab) {
                custom_fields.push({ key: 'preview', label: ' ', _classes: 'th-icon' })
            }

            
            custom_fields.push(
                { key: 'reference_number', label: 'Reference Number'},
                { key: 'form'},
            )


            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Created' })
                custom_fields.push({ key: 'routing', label: 'Routing Status'})
            }
            

            if(this.$store.getters.can('view-form-data')) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },
        
        active_forms: function () {
            return this.form_data_list.filter((item) => {
                return item.deleted_at == null
            })
        },

        inactive_forms: function () {
            return this.form_data_list.filter((item) => {
                return item.deleted_at != null
            })
        },
    },
    methods: {
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/form-managements/form-data/restore/' + item.reference_number, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${item.form_details.form.name} successfully restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.form_data_list.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish()
                }
            })
        },
        changeTab: function (item) {
            this.my_tab = item
        },      
        sortFormDataList: function(array) {
            return array.sort(function (x, y) {
                return y.reference_number - x.reference_number;
            });
        },
        getFormsData: function () {
            this.$Progress.start()
            axios.get('/form-managements/form-data/list', {validateStatus: () => true}).then(response => {
               if(response.status == 200) {
                    this.form_data_list = response.data.data ? response.data.data.sort((x,y)=> { return new Date(y.created_at) - new Date(x.created_at)}) : []
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
        previewFormData: function (item) {
            this.printout_source = item.form_details.form.printout_source
            this.$Progress.start();
            switch (this.printout_source) {
                case 'Default':
                    this.preview_form_data = true;
                    this.previewing_form_data = true
                    this.selected_row_id = item.id;
                    this.getFormDataDetails(item).then(data => {
                        let rowCounter = 0;
                        this.form_data_preview_loaded = true;
                        for (let index = 0; index < data.form_detail_fields.length; index++) {
                            const elements = data.form_detail_fields[index].form_detail_field_value;
                            for (let i = 0; i < elements.length; i++) {
                                const el = elements[i];
                                if(parseInt(el.line_number) > rowCounter) {
                                    rowCounter = parseInt(el.line_number)
                                }
                            }
                        }

                        let sorted_detail_fields = FormManagementJS.sortDetailFields(data.form_detail_fields);

                        this.$nextTick(() => {
                            this.$refs._formDataPreview.control_number = `Ref. No.: ${item.reference_number}`
                            this.$refs._formDataPreview.detailsRowCount = rowCounter;
                            this.$refs._formDataPreview.organization_logo = this.$store.getters.getOrganizationLogo;
                            this.$refs._formDataPreview.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header');
                            this.$refs._formDataPreview.detail_fields = FormManagementJS.generateDetailField(sorted_detail_fields);
                            this.$refs._formDataPreview.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer');
                            this.$refs._formDataPreview.header_footer_field_values = FormManagementJS.generateHeaderFooterFieldValue(data.form_header_footer_fields);
                            this.$refs._formDataPreview.detail_field_values = FormManagementJS.generateDetailFieldValue(data.form_detail_fields);
                            this.$Progress.finish()
                        })
                    })
                    
                break;   

                default:
                    let data = {
                        reference_number:item.reference_number,
                        printout_source:this.printout_source,
                        action:'preview'
                    }
                    axios.post('/form-managements/form-data/route-custom-form',data,{validateStatus: () => true}).then(response => {
                        if(response.status == 200) {
                            var byteCharacters = atob(response.data);
                            var byteNumbers = new Array(byteCharacters.length);
                            for (var i = 0; i < byteCharacters.length; i++) {
                              byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            var byteArray = new Uint8Array(byteNumbers);
                            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                            var fileURL = URL.createObjectURL(file);
                            window.open(fileURL); 
                            this.$Progress.finish()
                        }
                    })

                break;
            }


        },
        getFormDataDetails: function (parameter) {
            let params_id = `${parameter.reference_number}~${parameter.form_details.form.id}`;
             return new Promise((resolve, reject) => {
                axios.get('/form-managements/form-data/show/' + params_id, {validateStatus: () => true}).then(response => {
                    if( response.status == 200 ) {
                        let data = response.data.data
                        resolve(data);
                    }
                })
            })
        },
        routeFormData:function (item) {
            this.printout_source = item.form_details.form.printout_source
            this.$Progress.start();
            switch (this.printout_source) {
                case 'Default':
                    this.selected_row_id = item.id;
                    this.routing_form_data = true
                    this.getFormDataDetails(item).then(data => {
                        let rowCounter = 0;
                        this.form_data_preview_loaded = true;
                        for (let index = 0; index < data.form_detail_fields.length; index++) {
                            const element = data.form_detail_fields[index];
                            if(element.form_detail_field_value.length > rowCounter) {
                                rowCounter = element.form_detail_field_value.length
                            }
                        }

                        let sorted_detail_fields = FormManagementJS.sortDetailFields(data.form_detail_fields);

                        this.$nextTick(() => {
                            this.$refs._formDataRoute.control_number = `Ref. No.: ${item.reference_number}`
                            this.$refs._formDataRoute.detailsRowCount = rowCounter;
                            this.$refs._formDataRoute.organization_logo = this.$store.getters.getOrganizationLogo;
                            this.$refs._formDataRoute.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header');
                            this.$refs._formDataRoute.detail_fields = FormManagementJS.generateDetailField(sorted_detail_fields);
                            this.$refs._formDataRoute.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer');
                            this.$refs._formDataRoute.header_footer_field_values = FormManagementJS.generateHeaderFooterFieldValue(data.form_header_footer_fields);
                            this.$refs._formDataRoute.detail_field_values = FormManagementJS.generateDetailFieldValue(data.form_detail_fields);

                            setTimeout(() => {
                                this.createPDF().then(response => {
                                    this.$store.commit("SET_FORM_DATA_PDF_BASE64", response);
                                    this.$store.commit("SET_FORM_DATA_PDF_REFERENCE_NUMBER", item.reference_number);
                                });
                            }, 200); // .2 seconds delay seconds delay
                            this.$Progress.finish()
                        })
                    })
                break;   

                default:
                    let data = {
                        reference_number:item.reference_number,
                        printout_source:this.printout_source,
                        action:'route'
                    }
                    axios.post('/form-managements/form-data/route-custom-form',data,{validateStatus: () => true}).then(response => {
                        if(response.status == 200) {
                            this.$store.commit("SET_FORM_DATA_PDF_BASE64", response.data);
                            this.$store.commit("SET_FORM_DATA_PDF_REFERENCE_NUMBER", item.reference_number);
                            this.$Progress.finish()
                        }
                    })

                break;
            }

        },
        createPDF: function () {
            return new Promise(async (resolve, reject) => {
                const options = {
                    margin: [.5,.5],
                    image: {type: 'jpeg', quality: 0.98 },
                    html2canvas: {dpi: 300, letterRendering: true,useCORS: true},
                    jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                }
                try {
                    const base64 = html2pdf().set(options).from(document.getElementById('form_data')).outputPdf().then( (pdf) => {
                        return btoa(pdf)
                    })

                    // const base64 = html2pdf().set(options).from(document.getElementById('form_data')).outputPdf().save()
                    resolve(base64);
                } catch (e) {
                    reject(e);
                }
            });
        },
    },
    watch: {
        '$store.state.formDataPdfBase64': function (value) {
            if(value) {
                this.$router.push(`/${this.$store.getters.getOrganizationName}/general-access/document-routing/create`);
            }
        },
        preview_form_data: function (value) {
            if(!value) this.previewing_form_data = false;
        }
    }
}
</script>

<style>

.th-icon {
    width: 70px !important;
}
</style>